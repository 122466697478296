<template>
    <div
        v-cloak
        :class="{
            'dn-form-field--required': required
        }"
        :data-has-errors="typeof errors !== 'undefined' && errors.length > 0"
        class="dn-form-field"
    >
        <div
            v-if="!!$slots.label"
            class="dn-form-field__label"
        >
            <slot name="label" />
        </div>

        <div
            v-if="!!$slots.description"
            class="dn-form-field__description"
        >
            <slot name="description" />
        </div>

        <div class="dn-form-field__wrapper">
            <slot />
        </div>

        <div
            v-if="!!$slots.info"
            class="dn-form-field__info"
        >
            <slot name="info" />
        </div>

        <div
            v-if="errors && errors.length > 0"
            class="dn-form-field__errors"
        >
            <ul>
                <li
                    v-for="(error, index) in errors"
                    :key="index"
                >
                    {{ error }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        errors: {
            type: Array,
            default() {
                return [];
            }
        },

        required: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="less" src="./form-field.less"></style>
